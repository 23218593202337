.phone-item {
    height: 45rem;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: #999;
    margin-top: 2rem;
    &--box {
        width: 100%;
        height: 80%;
        border-bottom: 1px solid rgba(153, 153, 153, 0.3);
        padding-top: 10px;
        align-items: center;
    }
    &--img {
        width: 100%;
        height: 30rem;
        object-fit: contain;
        background-color: black;
    }
    &--info {
        padding: 1rem 1.5rem;
        display: flex;
        flex-direction: column;
    }
    .textTitle {
        display: block;
        justify-content: center;
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 2.0rem;
        font-weight: 500;
        color: #000000;
        margin: auto;
    }
    .textTitleDiv {
        text-align: center;
        padding-right: 0rem;
        padding-left: 0rem;
    }
    .flex-row {
        display: flex;
        flex-direction: row;
        .title {
            font-size: 1.4rem;
            font-weight: 500;
            flex: 1;
            padding: 0px;
            margin: auto;
        }
        .price {
            font-weight: 500;
            font-size: 1.4rem;
            margin-left: -50%;
        }
    }
    .description {
        font-size: 1.4rem;
        width: 80%;
    }
    .buttonsViewAll {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 1rem;
        .more-info {
            width: 80%;
            border: none;
            height: 4rem;
            background-color: #000000;
            cursor: pointer;
            outline: none;
            color: #fff;
            font-size: 1.7rem;
            margin: 0 .5rem;
            padding-top: .5rem;
            padding-bottom: .5rem;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}