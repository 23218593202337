@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

                    body {
                        font-family: 'Montserrat', sans-serif;
                    }
                    /* Carousel Style */
                    
                    .slider-overlay {
                        position: absolute;
                        height: 100%;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        background-color: #000000;
                        opacity: 0.55;
                        color: #fff;
                    }
                    /* Section Title */
                    
                    .banner-caption {
                        bottom: 28%;
                    }
                    
                    .banner-caption h1 {
                        font-size: 53px;
                    }
                    
                    .banner-caption h2 {
                        font-size: 27px;
                    }
                    
                    .service-icon {
                        color: red;
                        height: 100px;
                        margin-top: 20px;
                    }
                    
                    .why-choose-icon {
                        color: red;
                        height: 75px;
                        margin-top: 20px;
                    }
                    
                    svg {
                        color: #f04016;
                    }
                    
                    .brandname {
                        font-size: 26px;
                    }
                    
                    .nav-element {
                        font-size: 17px;
                        line-height: 41px;
                        margin: 0px 15px;
                    }
                    
                    .companyname {
                        font-size: 18px;
                        font-weight: bold;
                    }
                    
                    .brand-logo {
                        height: 60px;
                    }
                    
                    .nav-bg {
                        background-color: #000000;
                    }
                    
                    .slider-package-info {
                        position: absolute;
                        /*top: 0px;*/
                        bottom: 15px;
                        left: 18%;
                    }
                    
                    .slider-package-info h3 {
                        font-size: 35px;
                    }
                    
                    .carousel-height {
                        height: 500px;
                    }
                    
                    .carousel-img {
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        height: 500px;
                    }
                    
                    .banner-caption .btn-outline-dark {
                        color: #fff;
                        border-color: #fff;
                        font-size: 16px;
                        padding: 10px 30px;
                    }
                    
                    .banner-caption .btn:hover {
                        background-color: #000000;
                    }
                    /*carousel btn */
                    
                    .btn-default.btn2 {
                        font-family: 'Montserrat';
                        display: inline-block;
                        color: #fff;
                        border: none;
                        font-size: 11px;
                        line-height: 20px;
                        font-weight: 700;
                        padding: 8px 20px;
                        text-decoration: none;
                        border-radius: 0px;
                        -moz-border-radius: 0px;
                        -webkit-border-radius: 0px;
                        margin-top: 0px;
                        margin-bottom: 0px;
                        text-transform: uppercase;
                        background: none;
                        position: relative;
                        border: 2px solid #fff;
                        letter-spacing: 1px;
                    }
                    
                    .btn-default.btn2:hover {
                        color: #000;
                        background: #fff;
                    }
                    
                    .btn-default.btn3 {
                        font-family: 'Montserrat';
                        display: inline-block;
                        color: #fff;
                        border: none;
                        font-size: 11px;
                        line-height: 20px;
                        font-weight: 700;
                        padding: 8px 20px;
                        text-decoration: none;
                        border-radius: 0px;
                        -moz-border-radius: 0px;
                        -webkit-border-radius: 0px;
                        margin-top: 0px;
                        margin-bottom: 0px;
                        text-transform: uppercase;
                        background: #000;
                        position: relative;
                        border: 2px solid #000;
                        letter-spacing: 1px;
                    }
                    
                    .btn-default.btn3:hover {
                        color: #000;
                        background: #fff;
                        border: 2px solid #000;
                    }
                    
                    .btn-default.btn4 {
                        font-family: 'Montserrat';
                        display: inline-block;
                        color: #fff;
                        border: none;
                        font-size: 11px;
                        line-height: 20px;
                        font-weight: 700;
                        padding: 4px 20px;
                        text-decoration: none;
                        -moz-border-radius: 0px;
                        -webkit-border-radius: 0px;
                        margin: 0px;
                        margin-left: 7px;
                        text-transform: uppercase;
                        background: #000;
                        position: relative;
                        border: 2px solid #000;
                        border-radius: 25px;
                        letter-spacing: 1px;
                    }
                    
                    .btn-default.btn4:hover {
                        color: #000;
                        background: #fff;
                        border: 2px solid #000;
                    }
                    
                    .btn-space {
                        margin-top: 20px;
                    }
                    
                    .title {
                        text-align: center;
                        color: #35373e;
                        font-weight: 700;
                        font-family: 'Montserrat';
                        font-size: 22px;
                        line-height: 1;
                        text-transform: uppercase;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        margin-bottom: 15px;
                        letter-spacing: 2px;
                    }
                    
                    .titleContactInfo {
                        text-align: center;
                        color: #35373e;
                        font-weight: 400;
                        font-family: 'Montserrat';
                        font-size: 18px;
                        line-height: 1;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        margin-bottom: 15px;
                        letter-spacing: 1px;
                    }
                    
                    .titleContactInfoCategory {
                        color: #35373e;
                        font-weight: 400;
                        font-family: 'Montserrat';
                        font-size: 14px;
                        padding: 20px;
                        margin-bottom: 15px;
                        letter-spacing: 1px;
                        border: 1px solid rgba(0, 0, 0, .125);
                    }
                    
                    .product-overview-title {
                        text-align: center;
                        color: #35373e;
                        font-weight: 700;
                        font-family: 'Montserrat';
                        font-size: 22px;
                        line-height: 1;
                        text-transform: uppercase;
                        padding-bottom: 20px;
                        margin-bottom: 15px;
                        letter-spacing: 2px;
                    }
                    
                    .product-overview-row {
                        display: flex;
                    }
                    
                    .sidebar-item {
                        /* position: absolute; */
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                    
                    .make-me-sticky {
                        position: -webkit-sticky;
                        position: sticky;
                        top: 10%;
                        padding: 0px;
                    }
                    
                    .section-top-padding {
                        padding-top: 50px;
                    }
                    
                    .section-bottom-padding {
                        padding-bottom: 50px;
                    }
                    
                    .about-paragraph {
                        text-align: center;
                        color: #777777;
                        font-size: 19px;
                        margin-bottom: 15px;
                    }
                    
                    .about-services {
                        text-align: center;
                        color: #777777;
                        font-size: 19px;
                        margin-bottom: 15px;
                    }
                    
                    .product-description-p {
                        text-align: left;
                        color: #777777;
                        font-size: 17px;
                        margin-bottom: 15px;
                    }
                    
                    .productcard-image {
                        height: 230px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        border-bottom: 1px solid rgba(0, 0, 0, .125);
                    }
                    
                    .productcard-title {
                        font-size: 20px;
                        color: #141414;
                    }
                    
                    .why-choose-title {
                        font-size: 25px;
                        color: #141414;
                    }
                    
                    .productcard-paragraph {
                        font-size: 15px;
                        color: #999999;
                        line-height: 1.6666667;
                        height: 70px;
                    }
                    
                    .why-choose-card {
                        background-color: rgb(255, 255, 255, 0);
                        background-clip: border-box;
                        border: none;
                    }
                    
                    .why-choose-paragraph {
                        font-size: 15px;
                        color: #999999;
                        line-height: 1.6666667;
                    }
                    
                    .card-content {
                        margin: 10px 10px;
                    }
                    
                    .why-choose-content {
                        margin: 10px 0px;
                    }
                    
                    .card-margin {
                        margin: 15px 0px;
                    }
                    
                    .light-background {
                        background: #f7f8f9;
                    }
                    
                    .view-all-txt {
                        text-align: center;
                        color: #000;
                        font-size: 19px;
                        margin-top: 15px;
                    }
                    
                    .view-all-txt a {
                        color: #000;
                        text-decoration: none;
                    }
                    
                    .contact-info-h3 {
                        margin: 0 0 30px 0;
                        font-size: 32px;
                        line-height: 32px;
                    }
                    
                    .contact-content {
                        clear: both;
                        padding: 25px 0;
                        border-bottom: 1px solid #eee;
                    }
                    
                    .contact-content>.icon {
                        position: relative;
                        display: block;
                        float: left;
                        font-size: 40px;
                        line-height: 40px;
                        font-weight: normal;
                        margin-right: 20px;
                        text-align: center;
                        cursor: default;
                        color: #000000;
                    }
                    
                    .contact-content>.contact-text {
                        position: relative;
                        display: block;
                        float: left;
                        width: calc(100% - 60px);
                    }
                    
                    .contact-content>.contact-text>h4 {
                        margin: 0;
                        font-size: 23px;
                        margin-bottom: 10px;
                        transition: all 0.3s;
                    }
                    
                    .contact-content>.contact-text>p {
                        margin: 0;
                    }
                    
                    .contact-content p {
                        font-size: 16px;
                    }
                    
                    .contact-content a {
                        color: #000;
                        text-decoration: none;
                    }
                    
                    .mb-40 {
                        margin-bottom: 40px !important;
                    }
                    
                    .contact input,
                    .contact select {
                        height: 50px;
                        text-indent: 10px;
                        font-size: 14px;
                    }
                    
                    .contact input,
                    .contact textarea,
                    .contact select {
                        width: 100%;
                        border: 1px solid #666;
                        margin-bottom: 3px;
                        font-size: 14px;
                    }
                    
                    a,
                    button,
                    input {
                        text-decoration: none;
                        transition: 0.4s;
                    }
                    
                    .help-block {
                        display: block;
                        margin-top: 5px;
                        margin-bottom: 10px;
                        color: #737373;
                    }
                    
                    .contact textarea {
                        resize: vertical;
                        padding: 10px;
                    }
                    
                    .contact input,
                    .contact textarea,
                    .contact select {
                        width: 100%;
                        border: 1px solid #666;
                        margin-bottom: 3px;
                    }
                    
                    .get-in-touch-top-padding {
                        padding-top: 15px;
                    }
                    /* Footer */
                    
                    .sec-padding {
                        padding: 60px 0 60px 0;
                    }
                    
                    .section-dark {
                        background-color: #000;
                    }
                    
                    .fo-map {
                        background-size: 100%;
                    }
                    
                    .fo-map {
                        width: 100%;
                        float: left;
                    }
                    
                    .footer-logo {
                        margin-bottom: 35px;
                    }
                    
                    .text-light {
                        color: #acacac;
                        font-size: 14px;
                    }
                    
                    .font-weight-5 {
                        font-weight: 500;
                    }
                    
                    .text-white {
                        color: #fff;
                    }
                    
                    .section-dark h4 {
                        font-size: 22px;
                        line-height: 25px;
                        margin-bottom: 18px;
                        font-style: normal;
                    }
                    
                    .footer-quick-links-4 {
                        width: 100%;
                        padding: 0 0 0 0;
                        margin: 0px;
                        font-size: 14px;
                    }
                    
                    ul {
                        list-style-type: none;
                    }
                    
                    .footer-quick-links-4 li {
                        width: 100%;
                        padding: 0px;
                        margin: 0;
                        display: inline-block;
                    }
                    
                    .footer-quick-links-4 li a {
                        padding: 3px 0;
                        margin: 0;
                        color: #ffffff;
                        text-decoration: none;
                        float: left;
                        display: inline-block;
                    }
                    
                    .footer-quick-links-4 li a i {
                        padding: 0 10px 0 0;
                    }
                    
                    .margin-bottom {
                        margin-bottom: 0px;
                    }
                    
                    .footer-social-icons.left-align {
                        text-align: left;
                    }
                    
                    .footer-social-icons {
                        width: 100%;
                        padding: 0px;
                        margin: 15px 0 15px 0;
                    }
                    
                    .footer-social-icons li {
                        padding: 0px;
                        margin: 0px;
                        display: inline-block;
                    }
                    
                    .footer-social-icons.icons-plain li a {
                        background: none;
                    }
                    
                    .footer-social-icons.icons-plain li a {
                        background: none;
                    }
                    
                    .footer-social-icons li a {
                        width: 36px;
                        height: 36px;
                        text-align: center;
                        line-height: 40px;
                        display: inline-block;
                        padding: 0px;
                        color: #fff;
                        background-color: #101010;
                    }
                    
                    .sec-padding-6 {
                        padding: 30px 0 30px 0;
                    }
                    
                    .section-medium-dark {
                        background-color: #161616;
                    }
                    
                    .fo-copyright-holder {
                        position: relative;
                        width: 100%;
                        float: left;
                        padding: 0px;
                        margin: 0px;
                        font-weight: normal;
                        font-style: normal;
                        line-height: 23px;
                        color: #727272;
                        font-size: 14px;
                    }
                    /* Footer */
                    /*SearchComponent*/
                    
                    .find-flight-form .form-group,
                    .find-hotel-form .form-group,
                    .find-tour-form .form-group,
                    .find-car-form .form-group {
                        position: relative;
                    }
                    
                    .form-group label {
                        font-weight: bold;
                        font-size: 16px;
                    }
                    
                    input.search-input {
                        border-radius: 0px;
                        height: 50px;
                        position: relative;
                        border: 1px solid #666;
                        font-size: 15px;
                        padding-left: 15px;
                    }
                    
                    .search-icon {
                        position: absolute;
                        width: 45px;
                        height: 45px;
                        text-align: center;
                        line-height: 45px;
                        font-size: 3rem !important;
                        right: 30px;
                        top: 12px;
                        color: #525252;
                    }
                    /* textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
} */
                    
                    .search-input:focus {
                        border: 2px solid #000000;
                        border-radius: 0px;
                        box-shadow: none;
                    }
                    
                    .searchbox {
                        display: flex;
                        justify-content: center;
                    }
                    
                    .category-name {
                        font-size: 19px;
                        font-weight: 500;
                        color: #000000;
                        text-transform: uppercase;
                        margin: 0px 20px;
                    }
                    
                    .category-name:hover {
                        color: #7c7c7c;
                    }
                    
                    .category-group {
                        margin-bottom: 20px;
                        display: flex;
                        justify-content: center;
                    }
                    
                    .search-component {
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
                    /*SearchCOmponent End*/
                    
                    .product-details-img.product-details-tab {
                        display: flex;
                        flex-direction: column;
                    }
                    
                    .slick-track-1 {
                        opacity: 1;
                        width: 1536px;
                    }
                    
                    .product-details-img.product-details-tab .zoompro-wrap.zoompro-2 {
                        display: inline-block;
                        position: relative;
                        width: 100%;
                        float: left;
                        /* overflow: hidden; */
                    }
                    
                    .slick-slider {
                        position: relative;
                        display: block;
                        box-sizing: border-box;
                        -webkit-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        -webkit-touch-callout: none;
                        -khtml-user-select: none;
                        touch-action: pan-y;
                        -webkit-tap-highlight-color: transparent;
                    }
                    
                    .slick-slider .slick-track,
                    .slick-slider .slick-list {
                        transform: translate3d(0, 0, 0);
                    }
                    
                    .slick-track {
                        position: relative;
                        top: 0;
                        left: 0;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    
                    .slick-initialized .slick-slide {
                        display: block;
                    }
                    
                    .slick-slide {
                        display: none;
                        float: left;
                        height: 100%;
                        min-height: 1px;
                    }
                    
                    .product-details-img.product-details-tab .zoompro-wrap.zoompro-2 .zoompro-border.zoompro-span img {
                        width: 100%;
                    }
                    
                    .slick-track {
                        position: relative;
                        top: 0;
                        left: 0;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    
                    element.style {
                        width: 144px;
                    }
                    
                    .product-dec-slider-2 .single-slide-item {
                        margin: 0 10px;
                        transition: all .3s linear;
                    }
                    
                    .slick-slide img {
                        display: block;
                    }
                    
                    .img-responsive {
                        max-width: 100%;
                        display: block;
                    }
                    
                    .slick-track:after {
                        clear: both;
                    }
                    
                    .slick-track:before,
                    .slick-track:after {
                        display: table;
                        content: '';
                    }
                    
                    .product-details-img.product-details-tab .zoompro-wrap.zoompro-2 .zoompro-border.zoompro-span img {
                        width: 100%;
                    }
                    
                    .slick-track-2 {
                        opacity: 1;
                        transform: translate3d(0px, 0px, 0px);
                    }
                    
                    .img-responsive {
                        max-width: 100%;
                        display: block;
                    }
                    
                    .product-dec-slider-2 .single-slide-item.slick-slide.slick-current img {
                        border: 1px solid #ea1b25;
                    }
                    
                    .product-dec-slider-2 .single-slide-item img {
                        margin: auto;
                        transition: all .3s linear;
                        border: 1px solid #e5e5e5;
                    }
                    
                    .slick-list {
                        position: relative;
                        display: block;
                        /* overflow: hidden; */
                        margin: 0;
                        padding: 0;
                    }
                    
                    .product-dec-slider-2 {
                        margin-top: 20px;
                    }
                    
                    .product-details-img.product-details-tab .zoompro-wrap.zoompro-2 {
                        display: inline-block;
                        position: relative;
                        width: 100%;
                        float: left;
                        /* overflow: hidden; */
                    }
                    
                    .product-details-img.product-details-tab {
                        display: flex;
                        flex-direction: column;
                    }
                    
                    .product-banner-image {
                        height: 100vh;
                        width: 100%;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                    
                    .product-detail-thumbnail {
                        height: 90px;
                        width: 90px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        margin: 20px 10px;
                    }
                    
                    .product-detail-thumbnail-group {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                    }
                    
                    .description-section {
                        background-color: #f6f6f6;
                        height: 100%;
                    }
                    
                    .product-overview-sticky {
                        position: -webkit-sticky;
                        position: sticky !important;
                        top: 0;
                    }
                    
                    .product-overview-col {
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                    
                    .product-img-col {
                        position: -webkit-sticky;
                        position: sticky;
                        top: 0;
                        z-index: 1019;
                    }
                    
                    .product-detail-container {
                        padding: 50px;
                    }
                    
                    .product-overview-title {
                        padding-top: 0px;
                    }
                    
                    .product-overview-row {
                        padding: 0px;
                        margin: 0px;
                    }
                    
                    .form-control {
                        border-radius: 0px;
                    }
                    
                    .form-control:focus {
                        border-color: #000000;
                        box-shadow: 0 0 0 0.2rem rgba(65, 65, 65, 0.25);
                        border: 2px solid;
                        border-radius: 0px;
                    }
                    
                    body,
                    tbody,
                    tbody td {
                        background-color: transparent;
                    }
                    
                    .serviceContaints p {
                        font-size: 1.3rem;
                    }
                    
                    .serviceContaints span {
                        font-size: 1.5rem;
                    }
                    
                    .serviceContaints ul {
                        margin-left: 3rem;
                    }
                    
                    .serviceContaints li {
                        list-style: square;
                        padding-left: 1rem;
                    }
                    
                    .product-overview-thumbnail {
                        width: 100%;
                    }
                    
                    .serviceContaints li {
                        font-size: 18px;
                        line-height: 35px;
                    }
                    
                    .serviceContaints span {
                        font-size: 18px;
                        line-height: 50px;
                        font-weight: 500;
                    }
                    
                    .serviceContaints p {
                        font-size: 18px;
                        line-height: 40px;
                        margin-top: 20px;
                        font-weight: 500;
                    }
                    
                    .serviceContaints {
                        margin-bottom: 50px;
                    }
                    /*Media Queries */
                    
                    @media (max-width: 992px) {
                        .banner-caption h2 {
                            font-size: 25px;
                        }
                        .banner-caption h1 {
                            font-size: 46px;
                        }
                        .banner-caption h1 br {
                            display: none;
                        }
                        .category-name {
                            font-size: 16px;
                        }
                    }
                    
                    @media (max-width: 1200px) {
                        .product-banner-image {
                            height: 438px;
                            width: 100%;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                        .sidebar-item {
                            position: unset;
                        }
                    }
                    
                    @media (max-width: 768px) {
                        .slider-package-info {
                            position: absolute;
                            /*top: 0px;*/
                            bottom: 18px;
                            left: 20px;
                            margin-left: 12%;
                        }
                        .banner-caption h2 {
                            font-size: 23px;
                        }
                        .banner-caption h1 {
                            font-size: 43px;
                        }
                        /*Media Queries */
                        @media (max-width: 992px) {
                            .banner-caption h2 {
                                font-size: 25px;
                            }
                            .banner-caption h1 {
                                font-size: 46px;
                            }
                            .banner-caption h1 br {
                                display: none;
                            }
                            .category-name {
                                font-size: 16px;
                            }
                        }
                        @media (max-width: 768px) {
                            .slider-package-info {
                                position: absolute;
                                /*top: 0px;*/
                                bottom: 18px;
                                left: 20px;
                                margin-left: 12%;
                            }
                            .banner-caption h2 {
                                font-size: 23px;
                            }
                            .banner-caption h1 {
                                font-size: 43px;
                            }
                            .banner-caption h1 br {
                                display: none;
                            }
                            .category-name {
                                font-size: 16px;
                            }
                        }
                        @media (max-width: 576px) {
                            .slider-package-info h3 {
                                font-size: 27px;
                            }
                            .banner-caption h2 {
                                font-size: 21px;
                            }
                            .banner-caption h1 {
                                font-size: 33px;
                            }
                            .banner-caption h1 br {
                                display: none;
                            }
                            .companyname {
                                display: none;
                            }
                        }
                    }
                    /*Media Queries End*/
.phone-grid {
  grid-column: 2/3;
  background-color: #fff;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fill, minmax(38rem, 1fr));
  grid-gap: 1.5rem; }
  @media screen and (min-width: 1600px) {
    .phone-grid {
      grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr)); } }

.pagination {
  grid-column: 2/3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center; }
  .pagination .tab {
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2); }
  .pagination ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden; }
    .pagination ul li {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 20rem;
      background-color: #fff;
      list-style: none;
      cursor: pointer;
      position: relative; }
      .pagination ul li:active {
        color: #43ABC9; }
        .pagination ul li:active:nth-of-type(1) ~ .bar {
          left: 0rem; }
        .pagination ul li:active:nth-of-type(2) ~ .bar {
          left: 10rem; }
        .pagination ul li:active:nth-of-type(3) ~ .bar {
          left: 20rem; }
        .pagination ul li:active:nth-of-type(4) ~ .bar {
          left: 30rem; }
        .pagination ul li:active:nth-of-type(5) ~ .bar {
          left: 40rem; }
        .pagination ul li:active:nth-of-type(6) ~ .bar {
          left: 50rem; }
        .pagination ul li:active:nth-of-type(7) ~ .bar {
          left: 60rem; }
        .pagination ul li:active:nth-of-type(8) ~ .bar {
          left: 70rem; }
        .pagination ul li:active:nth-of-type(9) ~ .bar {
          left: 80rem; }
        .pagination ul li:active:nth-of-type(10) ~ .bar {
          left: 90rem; }
        .pagination ul li:active:nth-of-type(11) ~ .bar {
          left: 100rem; }
        .pagination ul li:active:nth-of-type(12) ~ .bar {
          left: 110rem; }
        .pagination ul li:active:nth-of-type(13) ~ .bar {
          left: 120rem; }
        .pagination ul li:active:nth-of-type(14) ~ .bar {
          left: 130rem; }
        .pagination ul li:active:nth-of-type(15) ~ .bar {
          left: 140rem; }
        .pagination ul li:active:nth-of-type(16) ~ .bar {
          left: 150rem; }
        .pagination ul li:active:nth-of-type(17) ~ .bar {
          left: 160rem; }
        .pagination ul li:active:nth-of-type(18) ~ .bar {
          left: 170rem; }
        .pagination ul li:active:nth-of-type(19) ~ .bar {
          left: 180rem; }
        .pagination ul li:active:nth-of-type(20) ~ .bar {
          left: 190rem; }
    .pagination ul .active {
      color: #43ABC9; }
      .pagination ul .active:nth-of-type(1) ~ .bar {
        left: 0rem; }
      .pagination ul .active:nth-of-type(2) ~ .bar {
        left: 10rem; }
      .pagination ul .active:nth-of-type(3) ~ .bar {
        left: 20rem; }
      .pagination ul .active:nth-of-type(4) ~ .bar {
        left: 30rem; }
      .pagination ul .active:nth-of-type(5) ~ .bar {
        left: 40rem; }
      .pagination ul .active:nth-of-type(6) ~ .bar {
        left: 50rem; }
      .pagination ul .active:nth-of-type(7) ~ .bar {
        left: 60rem; }
      .pagination ul .active:nth-of-type(8) ~ .bar {
        left: 70rem; }
      .pagination ul .active:nth-of-type(9) ~ .bar {
        left: 80rem; }
      .pagination ul .active:nth-of-type(10) ~ .bar {
        left: 90rem; }
      .pagination ul .active:nth-of-type(11) ~ .bar {
        left: 100rem; }
      .pagination ul .active:nth-of-type(12) ~ .bar {
        left: 110rem; }
      .pagination ul .active:nth-of-type(13) ~ .bar {
        left: 120rem; }
      .pagination ul .active:nth-of-type(14) ~ .bar {
        left: 130rem; }
      .pagination ul .active:nth-of-type(15) ~ .bar {
        left: 140rem; }
      .pagination ul .active:nth-of-type(16) ~ .bar {
        left: 150rem; }
      .pagination ul .active:nth-of-type(17) ~ .bar {
        left: 160rem; }
      .pagination ul .active:nth-of-type(18) ~ .bar {
        left: 170rem; }
      .pagination ul .active:nth-of-type(19) ~ .bar {
        left: 180rem; }
      .pagination ul .active:nth-of-type(20) ~ .bar {
        left: 190rem; }
    .pagination ul .bar {
      width: 10rem;
      background-color: #43ABC9;
      height: 5px;
      position: absolute;
      left: 0;
      bottom: 0;
      transition: 0.5s ease; }

.phone-item {
  height: 45rem;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: #999;
  margin-top: 2rem; }
  .phone-item--box {
    width: 100%;
    height: 80%;
    border-bottom: 1px solid rgba(153, 153, 153, 0.3);
    padding-top: 10px;
    align-items: center; }
  .phone-item--img {
    width: 100%;
    height: 30rem;
    object-fit: contain;
    background-color: black; }
  .phone-item--info {
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column; }
  .phone-item .textTitle {
    display: block;
    justify-content: center;
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 2.0rem;
    font-weight: 500;
    color: #000000;
    margin: auto; }
  .phone-item .textTitleDiv {
    text-align: center;
    padding-right: 0rem;
    padding-left: 0rem; }
  .phone-item .flex-row {
    display: flex;
    flex-direction: row; }
    .phone-item .flex-row .title {
      font-size: 1.4rem;
      font-weight: 500;
      flex: 1 1;
      padding: 0px;
      margin: auto; }
    .phone-item .flex-row .price {
      font-weight: 500;
      font-size: 1.4rem;
      margin-left: -50%; }
  .phone-item .description {
    font-size: 1.4rem;
    width: 80%; }
  .phone-item .buttonsViewAll {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem; }
    .phone-item .buttonsViewAll .more-info {
      width: 80%;
      border: none;
      height: 4rem;
      background-color: #000000;
      cursor: pointer;
      outline: none;
      color: #fff;
      font-size: 1.7rem;
      margin: 0 .5rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center; }

.search-box {
  position: fixed;
  font-size: 1.6rem;
  top: 5.5rem;
  left: 5rem;
  background-color: #f3f3f3;
  border: black 1rem solid;
  overflow: hidden;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; }
  .search-box input {
    margin-top: 1px;
    border: none;
    height: 3rem;
    text-indent: 1rem;
    color: #999;
    box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2); }
    .search-box input:focus {
      outline: none; }
  .search-box h3 {
    padding-top: 2.5rem;
    color: #999; }

.inputSearch {
  margin: 2px;
  height: 4rem;
  width: 500px;
  text-indent: 1rem;
  border: none;
  font-size: 1.4rem;
  color: #000000; }
  .inputSearch:focus {
    outline: none; }

.cart-box {
  width: 100%;
  height: 5rem;
  text-decoration: none;
  color: white;
  background-color: #43ABC9;
  display: flex;
  justify-content: center;
  align-items: center; }
  .cart-box p {
    margin: 0 .5rem 0 1rem; }

.check-box {
  font-size: 1.1rem;
  top: 26rem;
  margin: 2px;
  left: 5rem;
  width: 100vw;
  background-color: #f3f3f3;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center; }

.filter-list {
  width: 17rem; }
  .filter-list input[type=checkbox] {
    display: none; }
  .filter-list input:checked + label {
    background-color: #43ABC9;
    color: #fff; }
  .filter-list label {
    border-top: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    background-color: #fff;
    height: 3.5rem;
    color: #999;
    cursor: pointer;
    -webkit-user-select: none;
    /* Safari */
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2rem; }

.contentDiv {
  text-align: center;
  padding-right: 0rem;
  padding-left: 0rem; }

.contentDivSearch {
  margin-top: 2rem; }

.headerTitle {
  font-family: "Trebuchet MS", sans-serif;
  font-size: 3.5em;
  letter-spacing: 0.2px;
  font-weight: bold; }

.buttonContent a:link,
.buttonContent a:visited {
  background-color: white;
  color: black;
  font-size: 2.0em;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: none; }

.buttonContent a:hover,
.buttonContent a:active {
  background-color: #000000 !important;
  color: white !important; }

.searchContentIcon {
  border: black .007rem solid;
  border-left: none;
  text-align: right;
  vertical-align: middle;
  font-size: 2.5rem;
  padding: 0px;
  margin: 0px; }
  .searchContentIcon svg {
    color: gray;
    padding: 0px;
    margin: 0px; }
  .searchContentIcon svg:hover,
  .searchContentIcon a:active {
    color: #000000; }

.searchContentInput {
  border: #000000 solid .1rem;
  border-right: none; }
  .searchContentInput svg {
    color: gray; }
  .searchContentInput svg:hover,
  .searchContentInput a:active {
    color: #000000; }

.btn1{

    margin-top:23px;
    height:20px;
    text-align:center;
    

}
.contentDiv {
  text-align: center;
  padding-right: 0rem;
  padding-left: 0rem; }

.contentDivSearch {
  margin-top: 2rem; }

.headerTitle {
  font-family: "Trebuchet MS", sans-serif;
  font-size: 3.5em;
  letter-spacing: 0.2px;
  font-weight: bold; }

.buttonContent a:link,
.buttonContent a:visited {
  background-color: white;
  color: black;
  font-size: 2.0em;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: none; }

.buttonContent a:hover,
.buttonContent a:active {
  background-color: #000000 !important;
  color: white !important; }

.searchContentIcon {
  border: black .007rem solid;
  border-left: none;
  text-align: right;
  vertical-align: middle;
  font-size: 2.5rem;
  padding: 0px;
  margin: 0px; }
  .searchContentIcon svg {
    color: gray;
    padding: 0px;
    margin: 0px; }
  .searchContentIcon svg:hover,
  .searchContentIcon a:active {
    color: #000000; }

.searchContentInput {
  border: #000000 solid .1rem;
  border-right: none; }
  .searchContentInput svg {
    color: gray; }
  .searchContentInput svg:hover,
  .searchContentInput a:active {
    color: #000000; }

.phone-details {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden; }
  .phone-details h3:last-child {
    padding-right: 3rem; }
  .phone-details h3 {
    font-size: 2.5rem;
    font-weight: 400;
    color: #999;
    padding-left: 3rem;
    margin-top: 2rem; }
  .phone-details .phone {
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    overflow: hidden; }
    .phone-details .phone--box {
      background-color: #fff;
      width: 39.9rem;
      height: 32rem; }
      .phone-details .phone--box img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        padding-top: 1rem; }
    .phone-details .phone--title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0%; }
    .phone-details .phone .titleProduct {
      color: #000000;
      padding: 0%; }
    .phone-details .phone--description {
      color: #999;
      padding: 3rem;
      font-size: 1.6rem;
      text-align: justify;
      line-height: 2.5rem; }
  .phone-details .phone-stats {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    overflow: hidden;
    justify-content: space-around;
    padding: 3rem 3rem 4rem 4rem; }
    .phone-details .phone-stats .stat {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-transform: capitalize;
      height: 6rem; }
      .phone-details .phone-stats .stat p {
        font-size: 1.6rem;
        padding-top: .5rem; }
      .phone-details .phone-stats .stat h2 {
        font-size: 2rem;
        font-weight: 400; }
    .phone-details .phone-stats h1 {
      font-size: 2.6rem; }
    .phone-details .phone-stats p {
      color: #999; }
  .phone-details--grid {
    height: 577px;
    width: 100%;
    margin: 0 5rem;
    display: grid;
    grid-gap: 1.5rem;
    grid-template-rows: 1fr;
    grid-template-columns: 400px auto 20vw;
    background-color: #fff; }
  .phone-details .phone-sidebar {
    grid-column: 3 / 4;
    height: 30rem;
    background-color: #fff;
    border-radius: 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2); }
    .phone-details .phone-sidebar h3 {
      font-size: 2.5rem;
      font-weight: 400;
      color: #999; }
    .phone-details .phone-sidebar .cart-info {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center; }
    .phone-details .phone-sidebar .back-to-store {
      width: 15rem;
      border: none;
      border-radius: 2rem;
      height: 4rem;
      background-color: #43ABC9;
      margin-bottom: 2rem;
      cursor: pointer;
      outline: none;
      color: #fff;
      font-size: 1.6rem;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center; }
    .phone-details .phone-sidebar .add-to-cart {
      width: 15rem;
      border-radius: 2rem;
      margin: 0;
      font-size: 1.6rem;
      text-decoration: none;
      margin-bottom: 1rem;
      height: 4rem; }
      .phone-details .phone-sidebar .add-to-cart:after {
        content: '\00a0to cart'; }
      .phone-details .phone-sidebar .add-to-cart .cart {
        display: none; }
    .phone-details .phone-sidebar .cart-box {
      font-size: 1.6rem; }

.add-to-cart {
  width: 8rem;
  border: none;
  border-radius: 3rem;
  height: 3rem;
  background-color: #43ABC9;
  cursor: pointer;
  outline: none;
  color: #fff;
  font-size: 1.2rem;
  margin: 0 .5rem;
  display: flex;
  align-items: center;
  justify-content: center; }
  .add-to-cart .cart {
    margin: 0 .7rem 0 -.2rem; }

.cart-page {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden; }
  .cart-page h1 {
    font-size: 3rem;
    color: #999;
    text-transform: uppercase; }
  .cart-page--items {
    min-height: 58rem;
    border-radius: 1.5rem;
    overflow: hidden;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
    padding: 10rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .cart-page--items .item-list {
      width: 60%;
      box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2); }
      .cart-page--items .item-list .item {
        height: 16rem;
        width: 100%;
        border-bottom: 1px solid rgba(211, 211, 211, 0.6);
        display: flex;
        align-items: flex-start; }
        .cart-page--items .item-list .item--box {
          height: 100%;
          width: 20rem; }
          .cart-page--items .item-list .item--box img {
            object-fit: contain;
            height: 100%;
            width: 100%;
            padding: .5rem 0; }
        .cart-page--items .item-list .item--details {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          width: 100%;
          height: 100%; }
          .cart-page--items .item-list .item--details p {
            margin: 0;
            padding: 0; }
          .cart-page--items .item-list .item--details .title {
            font-size: 2.4rem; }
          .cart-page--items .item-list .item--details .price, .cart-page--items .item-list .item--details .quantity {
            font-size: 1.8rem;
            font-weight: 500; }
          .cart-page--items .item-list .item--details .quantity {
            width: 12rem; }
          .cart-page--items .item-list .item--details .block {
            padding-bottom: 6px; }
          .cart-page--items .item-list .item--details .remove {
            width: 10rem;
            height: 3rem;
            border: none;
            color: #fff;
            background-color: #D24D57;
            cursor: pointer;
            margin-bottom: 5px; }
          .cart-page--items .item-list .item--details .flex-row {
            display: flex;
            align-items: center; }
            .cart-page--items .item-list .item--details .flex-row .add, .cart-page--items .item-list .item--details .flex-row .sub {
              margin-right: 1rem;
              font-size: 2.5rem;
              width: 2.2rem;
              height: 2.2rem;
              border-radius: 50%;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              cursor: pointer;
              -webkit-touch-callout: none;
              /* iOS Safari */
              -webkit-user-select: none;
              /* Safari */
              /* Konqueror HTML */
              /* Firefox */
              -ms-user-select: none;
              /* Internet Explorer/Edge */
              user-select: none;
              /* Non-prefixed version, currently supported by Chrome and Opera */ }
            .cart-page--items .item-list .item--details .flex-row .add {
              background-color: #28a745;
              padding-bottom: .5px;
              padding-left: 1px; }
            .cart-page--items .item-list .item--details .flex-row .sub {
              background-color: #D24D57;
              padding-bottom: 2.5px;
              padding-left: 1px;
              font-size: 4rem; }
  .cart-page--grid {
    display: grid;
    grid-template-columns: auto 20vw;
    grid-gap: 1.5rem;
    width: 100%;
    height: 100%;
    margin: 2rem 5rem; }
  .cart-page--sidebar {
    grid-column: 2/3;
    height: 30rem;
    background-color: #fff;
    border-radius: 1rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2); }
    .cart-page--sidebar a {
      display: flex;
      align-items: center;
      justify-content: center; }
    .cart-page--sidebar h2 {
      font-size: 2.6rem;
      font-weight: 400; }
    .cart-page--sidebar .total {
      font-size: 2rem; }
    .cart-page--sidebar button, .cart-page--sidebar a {
      cursor: pointer;
      width: 15rem;
      height: 3.5rem;
      border: none;
      color: #fff;
      font-size: 1.4rem;
      text-decoration: none; }
      .cart-page--sidebar button:focus, .cart-page--sidebar a:focus {
        outline: none; }
    .cart-page--sidebar button:disabled,
    .cart-page--sidebar button[disabled] {
      border: 1px solid #999999;
      background-color: #cccccc;
      color: #666666; }
    .cart-page--sidebar .go-back {
      background-color: #43ABC9; }
    .cart-page--sidebar .clear-cart {
      background-color: #D24D57; }
    .cart-page--sidebar .buy-now {
      background-color: #28a745; }
    .cart-page--sidebar > * {
      margin: .7rem 0; }

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75); }

.Modal {
  position: absolute;
  top: 40px;
  left: 30%;
  right: 30%;
  bottom: 40px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px; }

.Modal::-webkit-scrollbar {
  width: 8px; }

.Modal::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px; }

.Modal::-webkit-scrollbar-track {
  background: #fff; }

p {
  word-wrap: break-word; }

.imgBox {
  width: 80%;
  height: 25rem;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
  display: flex;
  align-items: center;
  padding-bottom: 2rem; }
  .imgBox--img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.key {
  text-transform: uppercase;
  font-weight: 500 !important; }

.custom-ui h6 {
  margin-top: 24px !important;
  width: 80%; }

.grid-key:nth-child(-n+8) {
  display: none; }

.grid-value:nth-child(-n+8) {
  display: none; }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }

body {
  box-sizing: border-box; }

html::-webkit-scrollbar {
  width: 8px; }

html::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px; }

html::-webkit-scrollbar-track {
  background: #fff; }

.main-grid {
  font-size: 1.6rem;
  height: 100%;
  width: 100%;
  margin: 2rem 5rem;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-rows: 1fr 4.4rem;
  grid-template-columns: 20vw auto; }

.App {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

