.add-to-cart {
  width: 8rem;
  border: none;
  border-radius: 3rem;
  height: 3rem;
  background-color: #43ABC9;
  cursor: pointer;
  outline: none;
  color: #fff;
  font-size: 1.2rem;
  margin: 0 .5rem;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  .cart {
    margin: 0 .7rem 0 -.2rem;
  }
}