.imgBox {
    width: 80%;
    height: 25rem;
    border-bottom: 1px solid rgba(153, 153, 153, 0.3);
    display: flex;
    align-items: center;
    padding-bottom: 2rem;

    &--img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
} 

.key {
  text-transform: uppercase;
  font-weight: 500 !important;
}

.custom-ui {
  h6 {
    margin-top: 24px !important;
    width: 80%;
  }
}

.grid-key:nth-child(-n+8) {
 display: none;
}

.grid-value:nth-child(-n+8) {
 display: none;
}

// .value:nth-child(-n+4) {
//   display: none;
// }