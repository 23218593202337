.filter-list {

  width: 17rem;

  input[type=checkbox] {
    display: none;
  }

  input:checked+label {
    background-color: #43ABC9;
    color: #fff;
    // border: none;
  }

  label {
    border-top: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    background-color: #fff;
    height: 3.5rem;
    color: #999;
    cursor: pointer;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2rem;
  }
}