.search-box {
    position: fixed;
    font-size: 1.6rem;
    top: 5.5rem;
    left: 5rem;
    background-color: #f3f3f3;
    border: black 1rem solid;
    overflow: hidden;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    input {
        margin-top: 1px;
        border: none;
        height: 3rem;
        text-indent: 1rem;
        color: #999;
        box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
        &:focus {
            outline: none;
        }
    }
    h3 {
        padding-top: 2.5rem;
        color: #999;
    }
}

.inputSearch {
    margin: 2px;
    height: 4rem;
    width: 500px;
    text-indent: 1rem;
    border: none;
    font-size: 1.4rem;
    color: #000000;
    &:focus {
        outline: none;
    }
}