.phone-grid {
    grid-column: 2/3;
    background-color: #fff;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(auto-fill, minmax(38rem, 1fr));
    grid-gap: 1.5rem;
    @media screen and (min-width: 1600px) {
        grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
    }
}

.pagination {
    grid-column: 2/3;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .tab {
        box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
    }
    ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
        position: relative;
        overflow: hidden;
        li {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 20rem;
            background-color: #fff;
            list-style: none;
            cursor: pointer;
            position: relative;
            &:active {
                color: #43ABC9;
                @for $key from 1 through 20 {
                    &:nth-of-type(#{$key})~.bar {
                        left: $key * 10rem - 10rem;
                    }
                }
            }
        }
        .active {
            color: #43ABC9;
            @for $key from 1 through 20 {
                &:nth-of-type(#{$key})~.bar {
                    left: $key * 10rem - 10rem;
                }
            }
        }
        .bar {
            width: 10rem;
            background-color: #43ABC9;
            height: 5px;
            position: absolute;
            left: 0;
            bottom: 0;
            transition: 0.5s ease;
        }
    }
}