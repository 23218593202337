*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
}

body {
  box-sizing: border-box;
}

  html::-webkit-scrollbar {
    width: 8px;
  }
  
  html::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }

  html::-webkit-scrollbar-track {
    background: #fff;
    // border-radius: 20px;
  }

@import './components/homePage/HomePage.scss';