.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
}

.Modal {
  position: absolute;
  top: 40px;
  left: 30%;
  right: 30%;
  bottom: 40px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

  .Modal::-webkit-scrollbar {
    width: 8px;
  }
  
  .Modal::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }

  .Modal::-webkit-scrollbar-track {
    background: #fff;
    // border-radius: 20px;
  }

  p {
    word-wrap: break-word;
  }