.main-grid {
  font-size: 1.6rem;
  height: 100%;
  width: 100%;
  margin: 2rem 5rem;

  display: grid;
  grid-gap: 1.5rem;
  grid-template-rows: 1fr 4.4rem;
  grid-template-columns: 20vw auto;
}

.App {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
