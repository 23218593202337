.check-box {
    font-size: 1.1rem;
    top: 26rem;
    margin: 2px;
    left: 5rem;
    width: 100vw;
    background-color: #f3f3f3;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
}